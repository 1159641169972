export function addParamToUrl(url, key, value) {
  if (!value) {
    return url;
  }
  var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
  var separator = url.indexOf('?') !== -1 ? '&' : '?';
  if (url.match(re)) {
    return url.replace(re, '$1' + key + '=' + value + '$2');
  } else {
    return url + separator + key + '=' + value;
  }
}

export default function fetchHandler(authStatus = 401, authCode = 'code') {
  const _fetch = window.fetch;

  window.fetch = function() {
    const args = [].slice.call(arguments);
    return new Promise(function(resolve, reject) {
      // origin fetch
      _fetch.apply(this, args).then(response => {
        if (response.status === authStatus) {
          let location;
          try {
            location = Object.fromEntries(response.headers).location;
          } catch(e) {
            location = response.headers.get('location');
          }

          if (location) {
            // fetch oidc
            _fetch(location, {
              headers: { 'X-Requested-With': 'XMLHttpRequest' },
              credentials: 'include'
            }).then(async authRes => {
              if (authRes.status === 200) {
                try {
                  const res = await authRes.json();
                  if (res.success) {
                    // refetch
                    const realUrl = addParamToUrl(args[0], authCode, res.data.value);
                    _fetch(realUrl, args[1]).then(reRes => {
                      resolve(reRes);
                    }).catch(err => {
                      reject(err);
                    });
                  } else {
                    window.location.href = res.data.value;
                  }
                } catch (e) {
                  // oidc无返回值
                  console.warn('there is no response in "/oidc/authorize".');
                  resolve(response);
                }
              } else {
                console.warn('sso-oidc need response 200 status!');
                resolve(response);
              }
            }).catch(err => {
              reject(err);
            });
          } else {
            console.warn('sso-oidc not find Location from response header or the result is not /oidc/authorize.');
            resolve(response);
          }
        } else {
          resolve(response);
        }
      }).catch(err => {
        reject(err);
      });
    });
  };
}