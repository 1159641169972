import { proxy } from './xhr-proxy';

export function addParamToUrl(url, key, value) {
  if (!value) {
    return url;
  }
  var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
  var separator = url.indexOf('?') !== -1 ? '&' : '?';
  if (url.match(re)) {
    return url.replace(re, '$1' + key + '=' + value + '$2');
  } else {
    return url + separator + key + '=' + value;
  }
}

export default function xhrHandler(statusCode = 401, authCode = 'code') {
  proxy({
    onResponse: (response, handler) => {
      if (response.status === statusCode) {
        // handle 401
        var locationUrl = response.headers.location;
        if (!locationUrl || locationUrl.indexOf('/oidc/authorize') === -1) {
          console.warn('sso-oidc not find Location from response header or the result is not /oidc/authorize.');
          handler.next(response);
        }
        var authXhr = new XMLHttpRequest();
        authXhr.open('get', locationUrl);
        authXhr.withCredentials = true;
        authXhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        authXhr.send();
        authXhr.onreadystatechange = function() {
          if (authXhr.readyState === 4 && authXhr.status !== 0) {
            if (authXhr.status === 200) {
              var res = JSON.parse(authXhr.responseText);
              if (res && res.data) {
                if (res.success) {
                  var reXhr = new XMLHttpRequest();
                  var { method, url, async, user, password, headers, body } = response.config;
                  var realUrl = addParamToUrl(url, authCode, res.data.value);
                  reXhr.open(method, realUrl, async, user, password);
                  reXhr.withCredentials = true;
                  Object.keys(headers).forEach(key => {
                    reXhr.setRequestHeader(key, headers[key]);
                  });
                  reXhr.send(body);
                  reXhr.onreadystatechange = function() {
                    if (reXhr.readyState === 4 && reXhr.status !== 0) {
                      console.log(reXhr)
                      handler.resolve({
                        config: reXhr.xhr.config,
                        status: reXhr.status,
                        headers: reXhr.xhr.config.headers,
                        response: reXhr.response
                      });
                    }
                  };
                } else {
                  window.location.href = res.data.value;
                }
              } else {
                // oidc无返回值
                console.warn('there is no response in "/oidc/authorize".');
                handler.next(response);
              }
            } else {
              console.warn('/oidc/authorize request failed!');
              handler.next(response);
            }
          }
        };
      } else {
        handler.next(response);
      }
    }
  });
}
